import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql, useStaticQuery, Link } from "gatsby";
import { getLgas, getStateNameOptions } from "../helpers/state";
import { getPartyName, getPartyOptions } from "../helpers/party";
import { toTitleCase } from "../helpers";
import * as queryString from "query-string";
import PropTypes from "prop-types";
function ContactPage(props) {
  const data = useStaticQuery(graphql`
    query {
      ngelectionapi {
        elections {
          id
          year
          name
          candidates {
            slug
            name
            image
            state
            lga
            party
            summary
          }
        }
      }
    }
  `);

  const {
    election: electionRaw,
    state: stateRaw,
    lga,
    party,
  } = queryString.parse(props.location.search);

  const { elections } = data.ngelectionapi;
  const candidates = elections.flatMap((e) => {
    return e.candidates.map((c) => {
      c.election = e;
      return c;
    });
  });
  const getBadgeColors = (election) => {
    if (election.name?.toLowerCase()?.includes("presidential")) {
      return "text-red-600 bg-red-200";
    }
    if (election.name?.toLowerCase()?.includes("senate")) {
      return "text-green-600 bg-green-200";
    }
    return "text-blue-600 bg-blue-200";
  };

  const states = getStateNameOptions();
  const parties = getPartyOptions();

  const defaultSelectedOptions = {
    election: electionRaw || "ALL",
    state: stateRaw || "ALL",
    lga: lga || "ALL",
    party: party || "ALL",
  };
  const [selectedOptions, setSelectedOptions] = useState(
    defaultSelectedOptions
  );

  const setSelectionOption = (name, evt) => {
    const updated = {
      ...selectedOptions,
    };
    if (name === "state") {
      updated.lga = "ALL";
    }
    updated[name] = evt.target.value;
    setSelectedOptions(updated);
    const query = new URLSearchParams(updated).toString();
    console.log("...", query, updated);
    props.navigate(`?${query}`);
  };

  const [lgaClassnames, setLgaClassnames] = useState();
  useEffect(() => {
    let classNames = "flex w-full";
    if (!selectedOptions.state || selectedOptions.state === "ALL") {
      classNames += " opacity-75 grayscale border-gray-300";
    }
    setLgaClassnames(classNames);
  }, [selectedOptions.state]);

  return (
    <Layout>
      <SEO
        keywords={[`elections`, `candidates`]}
        title={`Candidates ${
          defaultSelectedOptions.election &&
          defaultSelectedOptions.election !== "ALL"
            ? `for ${defaultSelectedOptions.election} `
            : ""
        } ${
          defaultSelectedOptions.state && defaultSelectedOptions.state !== "ALL"
            ? `in ${defaultSelectedOptions.state} State`
            : ""
        } ${
          defaultSelectedOptions.lga && defaultSelectedOptions.lga !== "ALL"
            ? `in ${defaultSelectedOptions.lga} Constituency`
            : ""
        } ${
          defaultSelectedOptions.party && defaultSelectedOptions.party !== "ALL"
            ? `in ${getPartyName(defaultSelectedOptions.party)}`
            : ""
        }`}
      />
      <section className="candidate">
        <div className="col-span-12 sticky">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Election
              </label>
              <select
                className="flex w-full"
                value={selectedOptions.election}
                onChange={(evt) => setSelectionOption("election", evt)}
              >
                <option>-- select an election --</option>
                {[{ name: "ALL" }, ...elections].map((e, i) => (
                  <option key={i}>{e.name}</option>
                ))}
              </select>
            </div>

            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                State
              </label>
              <select
                className="flex w-full"
                value={selectedOptions.state}
                onChange={(evt) => setSelectionOption("state", evt)}
              >
                <option>-- select a state --</option>
                {[{ value: "ALL" }, ...states].map((s, i) => (
                  <option value={s.value} key={i}>
                    {s.value}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Constituency
              </label>
              <select
                className={lgaClassnames}
                value={selectedOptions.lga}
                onChange={(evt) => setSelectionOption("lga", evt)}
                disabled={
                  !selectedOptions.state || selectedOptions.state === "ALL"
                }
              >
                <option>-- select a constituency --</option>
                {["ALL", ...getLgas(selectedOptions.state)].map((lga, i) => (
                  <option key={i}>{lga}</option>
                ))}
              </select>
            </div>

            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Party
              </label>
              <select
                className="flex w-full"
                value={selectedOptions.party}
                onChange={(evt) => setSelectionOption("party", evt)}
              >
                <option>-- select a party --</option>
                {[{ value: "ALL", label: "ALL" }, ...parties].map(
                  (party, i) => (
                    <option value={party.value} key={i}>
                      {party.label}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="border-b-4 border-black py-3 mb-3"></div>

        <section className="grid grid-cols-12 gap-8 relative">
          <div className="col-span-12 md:col-span-12 flex flex-wrap">
            {candidates
              .filter((c) =>
                ["ALL", c.election.name].includes(selectedOptions.election)
              )
              .filter(
                (c) =>
                  ["ALL", c.state].includes(selectedOptions.state) || !c.state
              )
              .filter(
                (c) => ["ALL", c.lga].includes(selectedOptions.lga) || !c.lga
              )
              .filter((c) =>
                ["ALL", c.party, ""].includes(selectedOptions.party)
              )
              .map((c, i) => {
                return (
                  <div
                    key={i}
                    className="first:ml-0 last:mr-0 md:px-2 md:w-1/5 md:max-w-sm md:my-2 my-2 text-ellipsis overflow-hidden"
                  >
                    <div className="py-4 px-6 pl-0">
                      {c.image && (
                        <Link to={`/candidate/${c.slug}`}>
                          <img
                            className="candidate-image w-full h-52 object-cover object-center rounded-md border border-gray-200 shadow-lg mb-3"
                            src={c.image}
                            alt={c.name + "'s image"}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = "/images/person-icon.png";
                            }}
                          />
                        </Link>
                      )}
                      <Link to={`/candidate/${c.slug}`}>
                        <h1 className="text-xl font-semibold text-gray-800">
                          {toTitleCase(c.name)}
                        </h1>
                      </Link>

                      <div className="py-2 text-sm text-gray-700">
                        <p>{c.summary}</p>
                        {c.state && (
                          <p>
                            <span className="font-bold">State: </span>
                            {c.state}
                          </p>
                        )}
                        {c.lga && (
                          <p>
                            <span className="font-bold">Constituency: </span>
                            {c.lga}
                          </p>
                        )}
                        {c.party && (
                          <p>
                            <span className="font-bold">Party: </span>
                            {getPartyName(c.party)}
                          </p>
                        )}
                      </div>
                      <span
                        className={`text-xs font-semibold inline-block py-1 px-2 rounded ${getBadgeColors(
                          c.election
                        )} uppercase last:mr-0 mr-1`}
                      >
                        {c.election.name}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </section>
      </section>
    </Layout>
  );
}

ContactPage.propTypes = {
  location: PropTypes.any,
  navigate: PropTypes.any,
};

export default ContactPage;
